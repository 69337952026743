import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const [iframeHeight, setIframeHeight] = useState("600px");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("e");
  const formId = searchParams.get("f");
  const [eventTitle, setEventTitle] = useState("");
  useEffect(() => {
    const handleMessage = (event) => {
      // Ensure the message is coming from the expected source (optional security check)

      if (event.data?.type === "iframeHeight") {
        setIframeHeight(`${event.data.height}px`);
      }
      if (event.data.isFormSubmitted !== undefined) {
        setIsFormSubmitted(event.data.isFormSubmitted);
      }
      if (event.data.eventTitle) {
        setEventTitle(event.data.eventTitle);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (isFormSubmitted) {
    return (
      <div className="mymd:h-[100vh] min-h-[100vh] w-full justify-center items-center flex">
        {eventTitle && (
          <Helmet>
            <title>{eventTitle}</title>
            <meta name="type" property="og:type" content="article" />
            <meta name="title" property="og:title" content={`${eventTitle}`} />
          </Helmet>
        )}

        <iframe
          // src={`http://localhost:3000/form/${eventId}/visitor/${formId}`}
          src={`https://app.warpbay.com/form/${eventId}/visitor/${formId}`}
          width="100%"
          height={iframeHeight}
          style={{ border: "none", minHeight: "100dvh", overflow: "hidden" }}
          title="Form"
        ></iframe>
      </div>
    );
  } else {
    return (
      <div className="overflow-hidden">
        {eventTitle && (
          <Helmet>
            <title>{eventTitle}</title>
            <meta name="type" property="og:type" content="article" />
            <meta name="title" property="og:title" content={`${eventTitle}`} />
          </Helmet>
        )}
        <iframe
          // src={`http://localhost:3000/form/${eventId}/visitor/${formId}`}
          src={`https://app.warpbay.com/form/${eventId}/visitor/${formId}`}
          width="100%"
          height={iframeHeight}
          style={{ border: "none", minHeight: "100dvh", overflow: "hidden" }}
          title="Form"
        ></iframe>
      </div>
    );
  }
};

export default Home;
